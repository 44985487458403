<script>
import {AgGridVue} from "ag-grid-vue";
import {mapState} from "vuex";
import StatusDisplayCell from "../grid/StatusDisplayCell";
import DateCell from "../grid/generic/DateCell";
import ActionsDisplayCell from "../grid/ActionsDisplayCell";
import SimplePriceDisplayCell from "../grid/SimplePriceDisplayCell";

export default {
  name: "PriceListRecordsGrid",
  props: ['priceListId'],
  data() {
    return {
      showActions: false,
      columnDefs: null,
      gridApi: null,
      columnApi: null,
      rowSelection: '',
      selectedArticle: false,
      showArticleSearch: false,
      searchTerm: '',
      showResults: false,
      shouldRefresh: false,
      defaultColDef: {
        floatingFilter: true,
      },
    };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    AgGridVue, StatusDisplayCell, DateCell, ActionsDisplayCell, SimplePriceDisplayCell
  },
  created() {
    this.rowSelection = "multiple";
  },
  beforeMount() {

    if (this.getCurrentInstance() === 'beverages') {
      this.columnDefs = [
        {
          field: "title",
          resizable: true,
          headerName: "Supplier Article",
          filter: 'agTextColumnFilter',
          filterParams: {
            filterOptions: ['contains'],
            suppressAndOrCondition: true,
            maxNumConditions: 1,
          },
          filterValueGetter: (params) => {
            let alcohol_percentage = '';

            try {
              alcohol_percentage = params.data.supplier_article.alcohol_percentage
            } catch (e) {
              alcohol_percentage = 'NA';
            }

            let productTitle = '';

            if (params.data.supplier_article) {
              productTitle = params.data.supplier_article.name;
            }

            if (params.data.title) {
              productTitle = params.data.title.title;
            }

            return `${productTitle.toLowerCase()} -
            ${params.data.inner_content_count}/${params.data.bottles_per_case}/${alcohol_percentage}/
            ${this.determineGiftBoxText(params.data.supplier_article)}`;

          },
          cellStyle: {'text-align': 'left'},
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          cellRenderer: (params) => {
            let alcohol_percentage = '';

            try {
              alcohol_percentage = params.data.supplier_article.alcohol_percentage
            } catch (e) {
              alcohol_percentage = 'NA';
            }

            let productTitle = '';

            if (params.data.supplier_article) {
              productTitle = params.data.supplier_article.name;
            }

            if (params.data.title) {
              productTitle = params.data.title.title;
            }

            return `${productTitle} - ${params.data.inner_content_count}/${params.data.bottles_per_case}/${alcohol_percentage}/${this.determineGiftBoxText(params.data.supplier_article)}`;
          },
          minWidth: 200
        },
        {
          field: "article",
          headerName: "Matched Article",
          minWidth: 120,
          resizable: true,
          filter: 'agTextColumnFilter',
          filterValueGetter: (params) => {
            const article = params.data.article;

            if (article) {
              return `${article.title.toLowerCase()}`;
            }

            return null;
          },
          filterParams: {
            buttons: ['reset', 'apply'],
            filterOptions: ['contains', {
              displayKey: 'notBlanks',
              displayName: 'Not Blank',
              predicate: (_, cellValue) => cellValue !== null,
              numberOfInputs: 0,
            },
              {
                displayKey: 'blanks',
                displayName: 'Blank',
                predicate: (_, cellValue) => cellValue === null,
                numberOfInputs: 0,
              }
            ],
            suppressAndOrCondition: true,
            newRowsAction: 'keep'
          },
          cellStyle: {'text-align': 'left'},
          cellRenderer: (params) => {
            const article = params.data.article;
            if (article) {
              return `${article.title} - ${article.inner_content_count}/${article.content_count}/${article.alcohol_percentage}/${this.determineGiftBoxText(article)}`;
            }

            return "-"

          }
        },
        {
          field: "proposed_article1",
          headerName: "Proposed Article",
          width: 120,
          filter: true,
          resizable: true,
          cellStyle: {'text-align': 'left'},
          cellRenderer: (params) => {
            const proposedArticle = params.data.proposed_article1;
            const matchedArticle = params.data.article;

            if (! proposedArticle || matchedArticle) {
              return '';
            }

            if (proposedArticle && !matchedArticle) {
              return proposedArticle.latina_article_id + ' | ' + proposedArticle.title + ' - ' + proposedArticle.inner_content_count + '/'
                  + proposedArticle.content_count + '/' + proposedArticle.alcohol_percentage + '/' + this.determineGiftBoxText(proposedArticle);
            }
          },
          filterValueGetter: (params) => {
            const proposedArticle = params.data.proposed_article1;
            const matchedArticle = params.data.article;

            if (proposedArticle && !matchedArticle) {
              return proposedArticle.latina_article_id
            }

            return null;
          },
          filterParams: {
            buttons: ['reset', 'apply'],
            filterOptions: [
              {
                displayKey: 'contains',
                displayName: 'Contains',
                predicate: (_, cellValue) => cellValue !== _,
                numberOfInputs: 1,
              },
              {
                displayKey: 'notBlanks',
                displayName: 'Not Blank',
                predicate: (_, cellValue) => (cellValue !== null && cellValue !== ''),
                numberOfInputs: 0,
              },
              {
                displayKey: 'blanks',
                displayName: 'Blank',
                predicate: (_, cellValue) => cellValue === null,
                numberOfInputs: 0,
              }
            ],
            suppressAndOrCondition: true,
            newRowsAction: 'keep'
          }
        },
        {
          field: "proposed_article2",
          headerName: "Secondary Proposed Article",
          width: 120,
          filter: true,
          resizable: true,
          cellStyle: {'text-align': 'left'},
          cellRenderer: (params) => {
            const proposedArticle = params.data.proposed_article2;
            const matchedArticle = params.data.article;

            if (! proposedArticle || matchedArticle) {
              return '';
            }

            if (proposedArticle && !matchedArticle) {
              return proposedArticle.latina_article_id + ' | ' + proposedArticle.title + ' - ' + proposedArticle.inner_content_count +
                  '/' + proposedArticle.content_count + '/' + proposedArticle.alcohol_percentage + '/' + this.determineGiftBoxText(proposedArticle);
            }
          },
          filterParams: {
            buttons: ['reset', 'apply'],
            filterOptions: [
              {
                displayKey: 'contains',
                displayName: 'Contains',
                predicate: (_, cellValue) => cellValue !== _,
                numberOfInputs: 1,
              },
              {
                displayKey: 'notBlanks',
                displayName: 'Not Blank',
                predicate: (_, cellValue) => (cellValue !== null && cellValue !== ''),
                numberOfInputs: 0,
              },
              {
                displayKey: 'blanks',
                displayName: 'Blank',
                predicate: (_, cellValue) => cellValue === null,
                numberOfInputs: 0,
              },
            ],
            suppressAndOrCondition: true,
            newRowsAction: 'keep'
          }
        },
        {
          field: "unit_price",
          headerName: "Unit Price",
          width: 120,
          cellStyle: {'text-align': 'left'},
          cellRenderer: 'SimplePriceDisplayCell',
        },
        {
          field: "package_price",
          headerName: "Case Price",
          width: 120,
          cellStyle: {'text-align': 'left'},
          cellRenderer: 'SimplePriceDisplayCell',
        }
      ];
    } else {

      this.columnDefs = [
        {
          field: "title",
          resizable: true,
          headerName: "Supplier Title",
          filter: true,
          filterParams: {
            filterOptions: ['contains'],
            suppressAndOrCondition: true,
            maxNumConditions: 1,
          },
          cellStyle: {'text-align': 'left'},
          filterValueGetter: (params) => {
            let productTitle = '';

            if (params.data.supplier_article) {
              productTitle = params.data.supplier_article.name;
            }

            if (params.data.title) {
              productTitle = params.data.title.title;
            }

            return `${productTitle.toLowerCase()} - ${params.data.supplier_article.ean_article}`;

          },
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          cellRenderer: (params) => {

            let productTitle = '';

            if (params.data.supplier_article) {
              productTitle = params.data.supplier_article.name;
            }

            if (params.data.title) {
              productTitle = params.data.title.title;
            }

            return `${productTitle} - ${params.data.supplier_article.ean_article}`;
          },
          minWidth: 200
        },
        {
          field: "article",
          headerName: "Matched Article",
          minWidth: 120,
          resizable: true,
          filter: true,
          filterParams: {
            buttons: ['reset', 'apply'],
            filterOptions: [
              {
                displayKey: 'contains',
                displayName: 'Contains',
                predicate: (_, cellValue) => cellValue !== _,
                numberOfInputs: 1,
              },
              {
                displayKey: 'notBlanks',
                displayName: 'Not Blank',
                predicate: (_, cellValue) => cellValue !== null,
                numberOfInputs: 0,
              },
              {
                displayKey: 'blanks',
                displayName: 'Blank',
                predicate: (_, cellValue) => cellValue === null,
                numberOfInputs: 0,
              },
            ],
            suppressAndOrCondition: true,
          },
          cellStyle: {'text-align': 'left'},
          cellRenderer: (params) => {
            const article = params.data.article;
            if (article) {
              return `${article.title} - ${article.inner_content_count}/${article.content_count}/${article.alcohol_percentage}/${this.determineGiftBoxText(article)}`;
            }

            return "-"

          }
        },
        {
          field: "proposed_article1",
          headerName: "Proposed Article",
          width: 120,
          filter: true,
          resizable: true,
          cellStyle: {'text-align': 'left'},
          cellRenderer: (params) => {
            const proposedArticle = params.data.proposed_article1;
            const matchedArticle = params.data.article;

            if (proposedArticle && !matchedArticle) {
              return proposedArticle.latina_article_id + ' | ' + proposedArticle.title + ' - ' + proposedArticle.inner_content_count + '/'
                  + proposedArticle.content_count + '/' + proposedArticle.alcohol_percentage + '/' + this.determineGiftBoxText(proposedArticle);
            }
          },
          filterParams: {
            buttons: ['reset', 'apply'],
            filterOptions: [
              {
                displayKey: 'contains',
                displayName: 'Contains',
                predicate: (_, cellValue) => cellValue !== _,
                numberOfInputs: 1,
              },
              {
                displayKey: 'notBlanks',
                displayName: 'Not Blank',
                predicate: (_, cellValue) => (cellValue !== null && cellValue !== ''),
                numberOfInputs: 0,
              },
              {
                displayKey: 'blanks',
                displayName: 'Blank',
                predicate: (_, cellValue) => cellValue === null,
                numberOfInputs: 0,
              },
            ],
            suppressAndOrCondition: true,
          }
        },
        {
          field: "proposed_article2",
          headerName: "Secondary Proposed Article",
          width: 120,
          filter: true,
          resizable: true,
          cellStyle: {'text-align': 'left'},
          cellRenderer: (params) => {
            const proposedArticle = params.data.proposed_article2;
            const matchedArticle = params.data.article;

            if (proposedArticle && !matchedArticle) {
              return proposedArticle.latina_article_id + ' | ' + proposedArticle.title + ' - ' + proposedArticle.inner_content_count +
                  '/' + proposedArticle.content_count + '/' + proposedArticle.alcohol_percentage + '/' + this.determineGiftBoxText(proposedArticle);
            }
          },
          filterParams: {
            buttons: ['reset', 'apply'],
            filterOptions: [
              {
                displayKey: 'contains',
                displayName: 'Contains',
                predicate: (_, cellValue) => cellValue !== _,
                numberOfInputs: 1,
              },
              {
                displayKey: 'notBlanks',
                displayName: 'Not Blank',
                predicate: (_, cellValue) => (cellValue !== null && cellValue !== ''),
                numberOfInputs: 0,
              },
              {
                displayKey: 'blanks',
                displayName: 'Blank',
                predicate: (_, cellValue) => cellValue === null,
                numberOfInputs: 0,
              },
            ],
            suppressAndOrCondition: true,
          }
        },
        {
          field: "unit_price",
          headerName: "Unit Price",
          width: 120,
          cellStyle: {'text-align': 'left'},
          cellRenderer: 'SimplePriceDisplayCell',
        },
        {
          field: "package_price",
          headerName: "Case Price",
          width: 120,
          cellStyle: {'text-align': 'left'},
          cellRenderer: 'SimplePriceDisplayCell',
        }
      ];
    }
  },
  mounted() {
    this.fetchPriceRecords();
  },
  methods: {
    sizeToFit() {
      this.gridApi.sizeColumnsToFit();
    },
    async resetPriceRecords() {
      await this.$store.dispatch('pricelistRecords/resetPriceRecords');
    },
    async fetchPriceRecords() {
      await this.$store.dispatch('pricelistRecords/fetchPricelistRecords', this.priceListId);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.sizeToFit();
    },
    onSelectionChange() {
      const selectedRows = this.gridApi.getSelectedRows();

      if (selectedRows.length > 0) {
        this.showActions = true;
      } else {
        this.showActions = false;
      }
    },
    reset( rowNodes = null) {
      this.deselectAll();
      this.fetchPriceRecords();

      if (rowNodes) {

        this.gridApi.redrawRows(rowNodes);
      }
    },
    deselectAll() {
      this.gridApi.deselectAll();
      this.selectedArticle = false;
    },
    determineGiftBoxText(field) {

      let is_gift_box = '';

      try {
        is_gift_box = field.is_gift_box;

        if (is_gift_box === 1) {
          is_gift_box = 'GB';
        }

        if (is_gift_box === 0) {
          is_gift_box = 'NGB';
        }

      } catch (e) {
        is_gift_box = 'NA';
      }

      return is_gift_box;
    },

    async deleteSelectedPrices() {

      window.confirm('This will completely DELETE this Price Record from the system entirely. Are you sure you want to do this? ') &&
      await this.$store.dispatch('prices/deleteMultiplePrices', {
        prices: this.gridApi.getSelectedRows()
      });

      this.reset();

    },

    async performAssignment() {

      await this.$store.dispatch('prices/reassignMultiplePrices', {
        prices: this.gridApi.getSelectedRows(),
        article: this.selectedArticle.id
      });

      this.reset();

    },
    async acceptProposedArticles() {

      const rows = this.gridApi.getSelectedRows();

      await this.$store.dispatch('prices/acceptProposals', {
        prices: rows
      });

      this.reset(rows);
    },
    async acceptSecondaryProposedArticles() {
      await this.$store.dispatch('prices/acceptSecondaryProposals', {
        prices: this.gridApi.getSelectedRows()
      });

      this.reset();
    },
    async rejectProposedArticles() {

      await this.$store.dispatch('prices/rejectProposedArticles', {
        prices: this.gridApi.getSelectedRows()
      });

      this.reset();
    },
    async rejectMatchedArticles() {

      window.confirm('Are you sure you want to reject all matched articles? This action' +
          'is permanent.') &&
      await this.$store.dispatch('prices/rejectMatchedArticles', {
        prices: this.gridApi.getSelectedRows()
      });

      this.reset();
    },
    performSearch() {
      this.$store.dispatch('newPriceList/searchArticles', this.searchTerm)
      this.showResults = true;
    },
    selectArticle(article) {
      this.selectedArticle = article;
      this.searchTerm = article.latina_article_id + ' - ' + article.title;
      this.$store.dispatch('newPriceList/clearArticleSearch');
    },
  },

  computed: {
    directMatches() {
      if (this.rowData.length > 0) {
        let matchedRows = this.rowData.filter((item) => {
          return item.article !== null
        }).length

        return (matchedRows / this.rowData.length * 100).toFixed(2);
      }

      return 0;
    },
    primaryProposedPercentage() {
      if (this.rowData.length > 0) {
        let matchedRows = this.rowData.filter((item) => {
          return item.proposed_article1 !== null && item.article === null
        }).length

        return (matchedRows / this.rowData.length * 100).toFixed(2);
      }

      return 0;
    },
    secondaryProposedPercentage() {
      if (this.rowData.length > 0) {
        let matchedRows = this.rowData.filter((item) => {
          return item.proposed_article2 !== null;
        }).length

        return (matchedRows / this.rowData.length * 100).toFixed(2);
      }

      return 0;
    },
    ...mapState({
      rowData: state => state.pricelistRecords.pricelistRecords,
      count: state => state.pricelistRecords.pricelistRecords.length,
      searchResults: (state) => state.newPriceList.articleResults,
      isLoading: (state) => state.pricelistRecords.isFetching
    })
  },
};
</script>

<template>
  <b-overlay :show="this.isLoading" rounded="sm">
    <div class="actionsPanel alert  alert-info">
      <div class="actionsPanel__left">
        <div v-if="showActions">
          <b-button variant="secondary" @click="deselectAll" size="sm">Deselect All</b-button>
          <b-dropdown text="Accept Proposals" variant="primary" size="sm">
            <b-dropdown-item @click="acceptProposedArticles">Accept Primary Proposals</b-dropdown-item>
            <b-dropdown-item @click="acceptSecondaryProposedArticles">Accept Secondary Proposals</b-dropdown-item>
          </b-dropdown>
          <b-button variant="warning" @click="rejectProposedArticles" size="sm">Reject Proposals</b-button>
          <b-button variant="danger" @click="rejectMatchedArticles" size="sm">Reject Matches</b-button>
          <b-button variant="danger" @click="deleteSelectedPrices" size="sm">Delete Selected</b-button>
        </div>
      </div>
      <div class="actionsPanel__center">
        <div class="reAssignPrices" v-if="showActions">
          <div class="articleSearch">
            <span>Re-assign: </span>
            <b-form-input id="articleSearchSearch" placeholder="Search for Articles" ref="searchInput" size="sm"
                          v-on:keyup.enter="performSearch"
                          v-model="searchTerm"></b-form-input>
            <b-button variant="secondary" @click="performSearch" size="sm">Search</b-button>
          </div>
          <div class="articleResults__results" v-if="showResults">
            <div class="articleResults__result" v-for="result in searchResults" :key="result.id"
                 v-on:click="selectArticle(result)">
              {{ result.latina_article_id }} - {{ result.title }} - {{ result.content_count }} /
              {{ result.inner_content_count }} / {{ result.alcohol_percentage }}% /
              {{ determineGiftBoxText(result.is_gift_box) }}
            </div>
          </div>
          <b-button class="articleAssignBtn" variant="primary" size="sm" v-if="selectedArticle"
                    @click="performAssignment">Assign To Article
          </b-button>
        </div>
      </div>
      <div class="actionsPanel__right">
        <div>
          <span>{{ count }} Price records | {{ directMatches }}% Matched articles</span>
          <span>{{ primaryProposedPercentage }}% Primary proposed articles | {{ secondaryProposedPercentage }}% Secondary proposed articles</span>
        </div>
      </div>
    </div>
    <ag-grid-vue style=" width: 100%; min-height: 700px; height: 74vh"
                 class="ag-theme-alpine"
                 :defaultColDef="defaultColDef"
                 :columnDefs="columnDefs"
                 @grid-ready="onGridReady"
                 @selection-changed="onSelectionChange"
                 :rowSelection="rowSelection"
                 :rowData="rowData">
    </ag-grid-vue>
  </b-overlay>
</template>

<style lang="scss">
.actionsPanel {
  display: flex;
  justify-content: space-around;
  width: 100%;

  &__left {
    width: 560px;

    div {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    button {
      margin: 0 0 0 5px;
    }

  }

  &__center {
    width: 600px;
    position: relative;

    .reAssignPrices {
      display: flex;

      .articleSearch {
        width: 400px;
      }

      span {
        width: 140px;
      }

      .articleAssignBtn {
        width: 150px;
      }
    }
  }

  &__right {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    font-size: 12px;

    div {
      text-align: right;
      width: 370px;
    }
  }

  span {
    width: 400px;
  }
}
</style>
