<script>

export default {
  name: 'SupplierTitleDisplayCell',
  data: function () {
    return {
      renderValue: '',
      supplierData: false,
    };
  },
  beforeMount() {
    this.renderValue = this.params.value;
  },
  mounted() {
    if (this.params.data) {
      this.supplierData = this.params.data
    }
  }
};
</script>

<template>
  <div class="articleDisplay">
      <div class="articleDisplay__text">
        <span>{{ renderValue }}</span>
        <span class="legend comment" v-if="this.supplierData.comments"
              v-b-popover.hover.bottom="this.supplierData.comments">
        </span>
      </div>
  </div>
</template>

<style lang="scss">
.articleDisplay {
  display: flex;
  align-items: center;

  &__text {
    margin-right: 10px;
    display: inline-block;
  }

  &__button {
    background: transparent;
    font-weight: bold;
    border: 0;
    font-size: 16px;
    height: 27px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: #000;
      width: 16px;
    }

    &:hover {
      svg {
        fill: #fff;
      }
    }
  }
}
</style>