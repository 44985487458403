<script>
import {AgGridVue} from "ag-grid-vue";
import {mapState} from "vuex";
import StatusDisplayCell from "../grid/StatusDisplayCell";
import DateCell from "../grid/generic/DateCell";
import ActionsDisplayCell from "../grid/ActionsDisplayCell";

export default {
  name: "SuppliersGrid",
  data() {
    return {
      columnDefs: null,
      gridApi: null,
      defaultColDef: {
        floatingFilter: true,
      },
      columnApi: null
    };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    AgGridVue, StatusDisplayCell, DateCell, ActionsDisplayCell
  },
  beforeMount() {
    this.columnDefs = [
      {
        field: "code",
        resizable: true,
        headerName: "Currency Code",
        cellStyle: {'text-align': 'left'},
        width: 160,
        filter: false,
        cellRenderer: (params) => {
          if (! params.value) {
            return '';
          }

          if (params.value === 'EUR') {
            return params.value + ' - Primary';
          }

          return params.value;
        }
      },
      {
        field: "exchange_rate",
        resizable: true,
        headerName: "Exchange Rate",
        cellStyle: {'text-align': 'left'},
        width: 150
      },
      {
        field: "ignore_from_update",
        resizable: true,
        headerName: "Updates Ignored",
        cellStyle: {'text-align': 'left'},
        width: 150,
        cellRenderer: (params) => {
          if (params.value === null || params.value === undefined) {
            return '';
          }

          return (params.value === 0 ? 'No' : 'Yes');
        }
      },
      {
        field: "exchange_rate_last_updated",
        resizable: true,
        headerName: "Exchange Rate Last updated",
        cellStyle: {'text-align': 'left'},
        width: 300,
        cellRenderer: (params) => {
          if (! params.value) {
            return '';
          }
          return (this.getReadableDifference(params.value) + ' ago');
        }
      },
      {
        field: "id",
        headerName: "Actions",
        width: 210,
        cellStyle: {'text-align': 'left'},
        cellRenderer: 'ActionsDisplayCell',
        cellRendererParams: {
          actions: [
            {
              type: 'primary',
              label: 'Edit',
              clickHandler: {
                type: 'dispatch',
                action: 'currencies/openCurrencyModal'
              }
            }
          ]
        }
      }
    ];
  },
  mounted() {
    this.fetchCurrencies();
  },
  methods: {
    sizeToFit() {
      this.gridApi.sizeColumnsToFit();
    },
    fetchCurrencies() {
      this.$store.dispatch('currencies/fetchCurrencies');
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.sizeToFit();
    }
  },
  computed: mapState({
    rowData: state => state.currencies.currencies,
    isLoading: state => state.currencies.isFetching,
    error: state => state.currencies.error
  })
};
</script>

<template>
  <b-overlay :show="this.isLoading" rounded="sm">
    <ag-grid-vue style=" width: 100%; min-height: 730px; height: 77vh"
                 class="ag-theme-alpine"
                 :defaultColDef="defaultColDef"
                 :columnDefs="columnDefs"
                 :rowData="rowData">
    </ag-grid-vue>
  </b-overlay>
</template>
