<script>

export default {
  name: 'ArticleTitleDisplayCell',
  data: function () {
    return {
      renderValue: '',
      articleData: false,
      buttonVisible: false,
      className: '',
    };
  },
  beforeMount() {
    this.renderValue = this.params.value;

    if (! this.params.value) {
      return;
    }
    
    if (this.params.value.includes('Art. ')) {
      this.className = 'text-danger';
    }
  },
  mounted() {
    if (this.params.data) {
      this.articleData = this.params.data
    }
  },
  methods: {
    showPriceModal(){
      this.$store.dispatch('articles/openPriceModal', this.articleData);
    },
    copyPrice() {
      let copyText = this.articleData.title;

      if (this.articleData.title.title) {
        copyText = this.articleData.title.title;
      }

      navigator.clipboard.writeText(copyText).then(() => {});
    },
    deActivateArticle() {
      this.$store.dispatch('articles/openDeactivateModal', this.articleData);
    }
  }
};
</script>

<template>
  <div @mouseover="buttonVisible = true" @mouseleave="buttonVisible = false" class="articleDisplay">
    <RouterLink :to="{path: '/articles/' + this.articleData.latina_article_id}">
      <div :class="`articleDisplay__text ${className}`">
        <span>{{ renderValue }}</span>
        <span class="legend comment" v-if="this.articleData.comments"
              v-b-popover.hover.bottom="this.articleData.comments">
        </span>
      </div>
    </RouterLink>
    <b-button size="sm" v-if="buttonVisible" variant="primary" @click="showPriceModal" class="articleDisplay__button">
      <b-icon-plus></b-icon-plus>
    </b-button>
    <b-button size="sm" v-if="buttonVisible" variant="primary" @click="copyPrice" class="articleDisplay__button mx-1" title="Copy Title"><b-icon-clipboard-plus></b-icon-clipboard-plus></b-button>
  </div>
</template>

<style lang="scss">
.articleDisplay {
  display: flex;
  align-items: center;

  &__text {
    margin-right: 10px;
    display: inline-block;
  }

  &__button {
    background: transparent;
    font-weight: bold;
    border: 0;
    font-size: 16px;
    height: 27px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: #000;
      width: 16px;
    }

    &:hover {
      svg {
        fill: #fff;
      }
    }
  }
}
</style>