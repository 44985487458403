<script>
import {AgGridVue} from "ag-grid-vue";
import {mapState} from "vuex";
import StatusDisplayCell from "../grid/StatusDisplayCell";
import DateCell from "../grid/generic/DateCell";
import ActionsDisplayCell from "../grid/ActionsDisplayCell";
import SupplierTitleDisplayCell from "./SupplierTitleDisplayCell";

export default {
  name: "SuppliersGrid",
  data() {
    return {
      columnDefs: null,
      gridApi: null,
      role: null,
      defaultColDef: {
        floatingFilter: true,
      },
      columnApi: null
    };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    AgGridVue, StatusDisplayCell, DateCell, ActionsDisplayCell, SupplierTitleDisplayCell
  },
  beforeMount() {

    this.columnDefs = [];

    const userObject = JSON.parse(localStorage.getItem('latina-sam-user'));
    this.role = userObject.role;

    if (this.role === 'admin') {
      this.columnDefs = [...this.columnDefs, {
        field: "id",
        resizable: false,
        headerName: "Actions",
        width: 130,
        cellStyle: {'text-align': 'left'},
        cellRenderer: 'ActionsDisplayCell',
        cellRendererParams: {
          actions: [
            {
              type: 'primary',
              label: 'Edit',
              clickHandler: {
                type: 'dispatch',
                action: 'suppliers/openSupplierModal'
              },
            },
            {
              type: 'warning',
              label: 'Merge',
              clickHandler: {
                type: 'dispatch',
                action: 'suppliers/openSupplierMergeModal',
              },
            }
          ]
        }
      },]
    }

    this.columnDefs = [
      ...this.columnDefs,
      {
        field: "supplier_number",
        resizable: true,
        headerName: "Supplier Number",
        filter: true,
        filterParams: {
          filterOptions: [
            'empty',
            {
              displayKey: 'contains',
              displayName: 'Contains',
              predicate: (_, cellValue) => {
                if (cellValue && _.length > 0) {
                  return cellValue.toString().includes(_.map(item => item.toString()).join(','));
                }
              },
              numberOfInputs: 1
            },
            {
              displayKey: 'equalsBlank',
              displayName: 'Blank',
              predicate: (_, cellValue) => cellValue === null,
              numberOfInputs: 0,
            },
            {
              displayKey: 'notBlanks',
              displayName: 'Not Blank',
              predicate: (_, cellValue) => cellValue !== null,
              numberOfInputs: 0,
            },
          ],
          suppressAndOrCondition: true,
        },
        cellStyle: {'text-align': 'left'},
        width: 160,
      },
      {
        field: "name",
        resizable: true,
        headerName: "Supplier Name",
        cellStyle: {'text-align': 'left'},
        width: 400,
        filter: true,
        cellRenderer: 'SupplierTitleDisplayCell',
        filterParams: {
          filterOptions: [
            {
              displayKey: 'contains',
              displayName: 'Contains',
              predicate: (_, cellValue) => {
                if (cellValue && _.length > 0) {
                  return cellValue.toString().toLowerCase().includes(_.map(item => item.toString().toLowerCase()).join(','));
                }
              },
              numberOfInputs: 1
            }
          ],
          suppressAndOrCondition: true,
        },
      },
      {
        field: "representative.name",
        resizable: true,
        headerName: "Representative",
        cellStyle: {'text-align': 'left'},
        width: 150,
        filter: true,
        filterParams: {
          filterOptions: [
            {
              displayKey: 'contains',
              displayName: 'Contains',
              predicate: (_, cellValue) => {
                if (cellValue && _.length > 0) {
                  return cellValue.toString().toLowerCase().includes(_.map(item => item.toString().toLowerCase()).join(','));
                }
              },
              numberOfInputs: 1
            }
          ],
          suppressAndOrCondition: true,
        },
      },
      {
        field: "price_lists_count",
        resizable: true,
        headerName: "# of PriceLists",
        cellStyle: {'text-align': 'center'},
        filter: 'agNumberColumnFilter', filterParams: {
          filterOptions: [
            'equals',
            'greaterThan',
            'lessThan'
          ],
          suppressAndOrCondition: true
        },
        width: 150
      },
      {
        field: "category",
        resizable: true,
        headerName: "Category",
        cellStyle: {'text-align': 'left'},
        filter: 'agTextFilter', filterParams: {
          filterOptions: [
            'empty',
            {
              displayKey: 'equalsA',
              displayName: 'A',
              predicate: (_, cellValue) => cellValue === 'A',
              numberOfInputs: 0,
            },
            {
              displayKey: 'equalsB',
              displayName: 'B',
              predicate: (_, cellValue) => cellValue === 'B',
              numberOfInputs: 0,
            },
            {
              displayKey: 'equalsC',
              displayName: 'C',
              predicate: (_, cellValue) => cellValue === 'C',
              numberOfInputs: 0,
            },
            {
              displayKey: 'equalsBlank',
              displayName: 'Blank',
              predicate: (_, cellValue) => cellValue === null,
              numberOfInputs: 0,
            },
          ],
          suppressAndOrCondition: true
        },
        width: 120
      },
      {
        field: "honors_paper_trail",
        resizable: true,
        headerName: "Paper Trail",
        cellStyle: {'text-align': 'left'},
        filter: 'agTextFilter', filterParams: {
          filterOptions: [
            'empty',
            {
              displayKey: 'equalsYes',
              displayName: 'Yes',
              predicate: (_, cellValue) => cellValue === 1,
              numberOfInputs: 0,
            },
            {
              displayKey: 'equalsNo',
              displayName: 'No',
              predicate: (_, cellValue) => cellValue === 0,
              numberOfInputs: 0,
            },
          ],
          suppressAndOrCondition: true
        },
        cellRenderer: (params) => {
          return params.value === 1 ? 'Yes' : 'No';
        },
        width: 120
      },
      {
        field: "default_product_type",
        resizable: true,
        headerName: "Default Type",
        cellStyle: {'text-align': 'left'},
        filter: 'agTextFilter', filterParams: {
          filterOptions: [
            'empty',
            {
              displayKey: 'equalsT1',
              displayName: 'T1',
              predicate: (_, cellValue) => cellValue === 'T1',
              numberOfInputs: 0,
            },
            {
              displayKey: 'equalsT2',
              displayName: 'T2',
              predicate: (_, cellValue) => cellValue === 'T2',
              numberOfInputs: 0,
            },
          ],
          suppressAndOrCondition: true
        },
        width: 120
      },
      {
        field: "id",
        resizable: false,
        headerName: "Inc €0 - €1",
        cellStyle: {'text-align': 'left'},
        cellRenderer: (params) => {
          return '€' + params.data.tiers[0].markup_amount + ' + ' + params.data.tiers[0].markup_percentage + '%';
        },
        width: 120
      },
      {
        field: "id",
        resizable: false,
        headerName: "Inc €1 - €2",
        cellStyle: {'text-align': 'left'},
        cellRenderer: (params) => {
          return '€' + params.data.tiers[1].markup_amount + ' + ' + params.data.tiers[1].markup_percentage + '%';
        },
        width: 120
      },
      {
        field: "id",
        resizable: false,
        headerName: "Inc €2 - €3",
        cellStyle: {'text-align': 'left'},
        cellRenderer: (params) => {
          return '€' + params.data.tiers[2].markup_amount + ' + ' + params.data.tiers[2].markup_percentage + '%';
        },
        width: 120
      },
      {
        field: "id",
        resizable: false,
        headerName: "Inc €3 - €4",
        cellStyle: {'text-align': 'left'},
        cellRenderer: (params) => {
          return '€' + params.data.tiers[3].markup_amount + ' + ' + params.data.tiers[3].markup_percentage + '%';
        },
        width: 130
      },
      {
        field: "id",
        resizable: false,
        headerName: "Inc €4 - €5",
        cellStyle: {'text-align': 'left'},
        cellRenderer: (params) => {
          return '€' + params.data.tiers[4].markup_amount + ' + ' + params.data.tiers[4].markup_percentage + '%';
        },
        width: 120
      },
      {
        field: "id",
        resizable: false,
        headerName: "Inc €5+",
        cellStyle: {'text-align': 'left'},
        cellRenderer: (params) => {
          return '€' + params.data.tiers[5].markup_amount + ' + ' + params.data.tiers[5].markup_percentage + '%';
        },
        width: 120
      },

    ];
  },
  mounted() {
    this.fetchSuppliers();
  },
  methods: {
    sizeToFit() {
      this.gridApi.sizeColumnsToFit();
    },
    fetchSuppliers() {
      this.$store.dispatch('suppliers/fetchSuppliers');
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.sizeToFit();
    }
  },
  computed: mapState({
    rowData: state => state.suppliers.suppliers,
    isLoading: state => state.suppliers.isFetching,
    error: state => state.suppliers.error
  })
};
</script>

<template>
  <b-overlay :show="this.isLoading" rounded="sm">
    <ag-grid-vue style=" width: 100%; min-height: 730px; height: 77vh"
                 class="ag-theme-alpine"
                 :defaultColDef="defaultColDef"
                 :columnDefs="columnDefs"
                 :rowData="rowData">
    </ag-grid-vue>
  </b-overlay>
</template>
